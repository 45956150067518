<template>
  <div>
    <v-dialog v-model="appDialog" width="900px">
      <v-card color="#f4f6f9">
        <v-card-title> Device Files </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="5"
              style="background: #fff; border-radius: 7.5px; margin-top: 15px"
            >
              <v-treeview
                :items="items"
                :load-children="fetchChildren"
                item-key="id"
                activatable
                color="warning"
                open-on-click
                rounded
                hoverable
                dense
                transition
              >
                <template v-slot:prepend="{ item, open }">
                  <v-icon
                    color="#efdc06"
                    v-if="!item.file"
                    @click="onClick(item, open)"
                  >
                    {{ open ? "mdi-folder-open" : "mdi-folder" }}
                  </v-icon>
                  <v-icon color="#efdc06" v-else @click="onClick(item, open)">
                    {{ files[item.file] }}
                  </v-icon>
                </template>
                <template v-slot:label="{ item, open }">
                  <div @click="onClick(item, open)">{{ item.name }}</div>
                </template>
              </v-treeview>
            </v-col>

            <v-col class="pa-0 pl-1" cols="7" style="margin-top: 15px">
              <v-list dense color="#fff" style="border-radius: 7.5px">
                <v-subheader>Files</v-subheader>
                <v-list-item v-for="(item, i) in files" :key="i">
                  <v-list-item-content>
                    <v-list-item-title>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-wrap: wrap;
                          padding: 0 15px;
                        "
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                          "
                        >
                          <img :src="item.icon" v-if="item.icon" width="32" />
                          <img
                            :src="getBaseUrl + item.image"
                            v-else-if="item.image"
                            width="32"
                          />
                          <v-icon
                            v-else
                            :color="
                              item.isUploaded == false ? '#9b9b9b' : '#749ef2'
                            "
                            >mdi-file</v-icon
                          >{{ item.name }}
                          <div v-if="item.version" class="ml-1">
                            {{ "v" + item.version }}
                          </div>
                          <div v-if="item.versionName" class="ml-1">
                            {{ "v" + item.versionName }}
                          </div>
                        </div>
                        <v-autocomplete
                          v-model="item.action"
                          :items="getFileActions(item)"
                          label="Action"
                          style="max-width: 110px"
                          @change="updateFileAction(item)"
                        />
                        <!-- <v-icon
                          v-if="item.isUploaded == false"
                          @click="upload(item)"
                          >mdi-upload</v-icon
                        > -->
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <DropZone
                id="myVueDropzone"
                ref="myVueDropzone"
                :options="dropzoneOptions"
                @vdropzone-file-added="sendingFile"
                style="border-radius: 7.5px; margin-top: 5px"
              >
              </DropZone>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> Close </v-btn>
          <v-btn text @click="update"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ApkParser from "app-info-parser/src/apk";

export default {
  name: "DeviceFilesDialog",
  components: { DropZone: vue2Dropzone },
  props: ["dialog", "deviceId"],
  data: () => ({
    appDialog: false,
    items: [],
    files: [],
    folderFiles: [],
    workingFiles: [],
    currentFolderId: 0,
    currentFolderPath: null,
    dropzoneOptions: {
      autoProcessQueue: false,
      url: "http://localhost",
      previewsContainer: false,
    },
    fileActions: [
      { text: "No Action", value: null },
      { text: "Download", value: 1 },
      { text: "Install", value: 2 },
      { text: "Upload", value: 3 },
    ],
  }),
  computed: {
    getBaseUrl() {
      return this.$http.defaults.baseURL.replace("api/", "Resources/");
    },
  },
  watch: {
    async dialog(val) {
      this.appDialog = val;
      if (val) {
        try {
          if (this.deviceId && this.deviceId > 0) {
            this.files = [];
            this.items = [];
            let result = await this.getRootFiles();
            let files = result.filter((x) => !x.isDirectory);
            let folders = result.filter((x) => x.isDirectory);
            this.items.push(...folders);
            this.files = files;
          }
        } catch (error) {
          this.files = [];
          this.items = [];
        }
      }
    },
    appDialog(val) {
      val || this.close();
    },
  },

  methods: {
    async onClick(item, close) {
      if (!close) {
        try {
          let response = await this.getSubDeviceFileByDeviceFile(item.id);
          this.files = [];
          this.files = response.filter((x) => !x.isDirectory);
        } catch (error) {
          this.files = [];
        }
      } else {
        this.files = [];
      }
    },
    sendingFile(file) {
      if (file.name.includes(".apk")) {
        const parser = new ApkParser(file);
        parser
          .parse()
          .then((result) => {
            //console.log("app info ----> ", result);
            // console.log("icon base64 ----> ", result.icon);
            let apk = {
              name: file.name,
              package:
                typeof result.package == "undefined" ? "" : result.package,
              version:
                typeof result.versionName == "undefined"
                  ? ""
                  : result.versionName,
              icon: result.icon,
              action: null,
              file: file,
              isUploaded: false,
              deviceFileId: this.currentFolderId,
              deviceFilePath: this.currentFolderPath,
            };
            this.files.push(apk);
            this.workingFiles.push(apk);
          })
          .catch((err) => {
            let apk = {
              name: file.name,
              action: null,
              file: file,
              isUploaded: false,
              deviceFileId: this.currentFolderId,
              deviceFilePath: this.currentFolderPath,
            };
            this.files.push(apk);
            this.workingFiles.push(apk);
          });
      } else {
        let _file = {
          name: file.name,
          action: null,
          file: file,
          isUploaded: false,
          deviceFileId: this.currentFolderId,
          deviceFilePath: this.currentFolderPath,
        };
        this.files.push(_file);
        this.workingFiles.push(_file);
      }
    },
    async fetchChildren(item) {
      try {
        this.currentFolderId = item.id;
        this.currentFolderPath = item.path;

        this.files = [];
        item.children = [];
        let response = await this.getSubDeviceFileByDeviceFile(item.id);
        let files = response.filter((x) => !x.isDirectory);
        let folders = response.filter((x) => x.isDirectory);
        item.children.push(...folders);
        this.files = files;
      } catch (error) {
        this.files = [];
        item.children = [];
      }
    },
    async getRootFiles() {
      try {
        let response = await this.$http.get("DeviceFile/GetRootDeviceFiles", {
          params: {
            deviceId: this.deviceId,
          },
        });
        if (Array.isArray(response.data)) {
          response.data = response.data.map((x) => {
            if (!x.hasOwnProperty("children")) {
              x.children = [];
            }
            return x;
          });
          return response.data;
        }
      } catch (e) {}
      return [];
    },
    async getSubDeviceFileByDeviceFile(id) {
      try {
        let response = await this.$http.get(
          "DeviceFile/GetSubDeviceFileByDeviceFile",
          {
            params: {
              deviceId: this.deviceId,
              deviceFileId: id,
            },
          }
        );
        if (Array.isArray(response.data)) {
          response.data = response.data.map((x) => {
            if (!x.hasOwnProperty("children")) {
              x.children = [];
            }
            return x;
          });
          return response.data;
        }
      } catch (e) {}
      return [];
    },
    close() {
      this.appDialog = false;
      this.$emit("close");
    },
    getFileActions(item) {
      if (item.name.includes(".apk")) {
        return [
          { text: "No Action", value: null },
          { text: "Download", value: 1 },
          { text: "Install", value: 2 },
        ];
      } else if (
        item.serverPath ||
        (item.hasOwnProperty("isUploaded") && !item.isUploaded)
      ) {
        return [
          { text: "No Action", value: null },
          { text: "Download", value: 1 },
        ];
      } else {
        return [
          { text: "No Action", value: null },
          { text: "Upload", value: 3 },
        ];
      }
    },
    upload(item) {
      var data = new FormData();
      data.append("fileId", this.currentFolderId);
      data.append("deviceId", this.deviceId);
      data.append("file", item.file);
      data.append("action", item.action);
      data.append("version", item.version);
      data.append("package", item.package);
      if (item.icon) {
        let fileName = item.file.name.split(".").slice(0, -1).join(".");
        let imageName = fileName + ".jpg";
        data.append("image", this.dataURLtoFile(item.icon, imageName));
      }

      this.$http
        .post("DeviceFile/AddDeviceFileByFileId", data)
        .then((response) => {
          if (response.data.success) {
            item = response.data.data;
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },
    updateFileAction(item) {
      // console.log("updateFileAction", item);
      if (item.hasOwnProperty("id")) {
        let index = this.workingFiles.findIndex((x) => x.id == item.id);
        if (index > -1) {
          let file = this.workingFiles[index];
          file.action = item.action;
          file.deviceFileId = this.currentFolderId;
          file.deviceFilePath = this.currentFolderPath;
          this.workingFiles.splice(index, 1, file);
        } else {
          item.deviceFileId = this.currentFolderId;
          item.deviceFilePath = this.currentFolderPath;
          this.workingFiles.push(item);
        }
      } else if (item.hasOwnProperty("isUploaded")) {
        let index = this.workingFiles.findIndex(
          (x) => x.name == item.name && x.deviceFileId == item.deviceFileId
        );
        if (index > -1) {
          let file = this.workingFiles[index];
          file.action = item.action;
          file.deviceFileId = this.currentFolderId;
          file.deviceFilePath = this.currentFolderPath;
          this.workingFiles.splice(index, 1, file);
        } else {
          item.deviceFileId = this.currentFolderId;
          item.deviceFilePath = this.currentFolderPath;
          this.workingFiles.push(item);
        }
      } else {
        item.deviceFileId = this.currentFolderId;
        item.deviceFilePath = this.currentFolderPath;
        this.workingFiles.push(item);
      }
      // if (!(item.hasOwnProperty("isUploaded") && !item.isUploaded))
      //   this.$http
      //     .patch("DeviceFile/UpdateFileAction", null, {
      //       params: { fileId: item.id, action: item.action },
      //     })
      //     .then((response) => {
      //       if (response.data.success) {
      //         this.$toast.success(response.data.message, "Success", {
      //           position: "topRight",
      //         });
      //       } else {
      //         this.$toast.error(response.data.message, "Error", {
      //           position: "topRight",
      //         });
      //       }
      //     });
    },
    update() {
      this.$emit("actions", this.workingFiles);
      this.close();
    },
    save() {
      var data = new FormData();
      this.workingFiles.forEach((item, index) => {
        if (item.id) {
          data.append(`deviceFiles[${index}].id`, item.id);
        }
        if (item.deviceFileId) {
          data.append(
            `deviceFiles[${index}].deviceFileId`,
            this.currentFolderId
          );
        }
        if (item.file) {
          data.append(`deviceFiles[${index}].file`, item.file);
        }
        if (item.action) {
          data.append(`deviceFiles[${index}].action`, item.action);
        }
        if (item.version) {
          data.append(`deviceFiles[${index}].version`, item.version);
        }
        if (item.package) {
          data.append(`deviceFiles[${index}].package`, item.package);
        }
        if (item.icon) {
          let fileName = item.file.name.split(".").slice(0, -1).join(".");
          let imageName = fileName + ".jpg";
          data.append(
            `deviceFiles[${index}].image`,
            this.dataURLtoFile(item.icon, imageName)
          );
        }
      });

      this.$http
        .post("DeviceFile/UpdateFileActionList", data, {
          params: { deviceId: this.deviceId },
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
      this.close();
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style>
</style>